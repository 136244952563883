let localStorage;

const PREFIX = 'edvin.';

// eslint-disable-next-line no-undef
if (process.browser) {
  localStorage = window?.localStorage;
}

export function clear() {
  localStorage?.clear();
}

export function getData(key, ifAbsent = undefined, noJson = false) {
  return getDataLegacy(PREFIX + key, ifAbsent, noJson);
}

export function getDataLegacy(key, ifAbsent = undefined, noJson = false) {
  const data = localStorage?.getItem(key);
  if (!data) {
    return ifAbsent;
  }
  try {
    return noJson ? data : JSON.parse(data);
  } catch {
    removeData(key);
    return ifAbsent;
  }
}

export function setData(key, data, noJson = false) {
  localStorage?.setItem(PREFIX + key, noJson ? data : JSON.stringify(data));
}

export function removeData(key) {
  localStorage?.removeItem(PREFIX + key);
}

export function consumeData(key, ifAbsent = undefined, noJson = false) {
  const data = getData(key, ifAbsent, noJson);
  if (data) {
    removeData(key);
  }
  return data;
}

export function useLocalStorage(section) {
  const fnName = section.charAt(0).toUpperCase() + section.slice(1);
  let cache = getData(section, {});

  return {
    [`get${fnName}`]: () => cache,
    [`set${fnName}`]: (value) => {
      cache = value;
      setData(section, cache);
    },
    [`set${fnName}Items`]: (items) => {
      cache = { ...cache, ...items };
      setData(section, cache);
    },
    [`get${fnName}Item`]: (key) => cache[key],
    [`set${fnName}Defaults`]: (defaults) => {
      cache = {
        ...defaults,
        ...getData(section, {}),
      };
      setData(section, cache);
    },
    [`clear${fnName}`]: () => {
      cache = null;
      removeData(section);
    },
    [`reload${fnName}`]: () => {
      cache = getData(section, {});
    },
  };
}

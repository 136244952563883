export const noChangeLoader = ({ src }) => {
  return src;
};

const saveSrc = (src) => {
  return src.indexOf('?') > -1 ? src + '&' : src + '?';
};

export const imgixLoader = ({ src, width }) => {
  return `${saveSrc(src)}w=${width}&auto=compress,format&fit=clip`;
};

export const prismicLoader = ({ src, width }) => {
  return `${saveSrc(src)}w=${width}`;
};

export const img16to9Loader = ({ src, width }) => {
  return `${saveSrc(src)}w=${width}&ar=16:9&fit=crop&crop=faces`;
};

export const img4to3Loader = ({ src, width }) => {
  return `${saveSrc(src)}w=${width}&ar=4:3&fit=crop&crop=faces`;
};

export const calcImageSize = (
  imageWidth,
  imageHeight,
  containerWidth,
  containerHeight,
) => {
  const bestFitRatio = Math.min(
    containerWidth / imageWidth,
    containerHeight / imageHeight,
  );
  return {
    width: Math.round(imageWidth * bestFitRatio) || 1,
    height: Math.round(imageHeight * bestFitRatio) || 1,
  };
};

export const calcImageProps = (image, size) => {
  const key = `${size.width}.${size.height}`;
  if (image[key]) {
    return image[key];
  }
  image[key] = {
    ...calcImageSize(
      image.dimensions?.width ?? image.width,
      image.dimensions?.height ?? image.height,
      size.width,
      size.height,
    ),
    alt: image.alt ?? '',
    src: image.url,
  };
  return image[key];
};

export const calc16to9ImageProps = (image, maxWidth) =>
  calcRatioImageProps(9 / 16, image, maxWidth, 'aspect-h-9 aspect-w-16');

export const calc4to3ImageProps = (image, maxWidth) =>
  calcRatioImageProps(3 / 4, image, maxWidth, 'aspect-h-9 aspect-w-16');

export const calcTitleImageProps = (image, maxWidth) =>
  calcRatioImageProps(400 / 512, image, maxWidth, 'aspect-[512/400]');

const calcRatioImageProps = (ratio, image, maxWidth, className) => {
  if (!image) {
    return;
  }

  if (image[maxWidth]) {
    return image[maxWidth];
  }
  if (image.dimensions) {
    const width =
      image.dimensions.width > 0 ? maxWidth : image.dimensions.width;
    image[maxWidth] = {
      alt: image.alt ?? '',
      src: image.url,
      width: width,
      height: Math.round(width * ratio),
      layout: 'responsive',
      className: '',
    };
  } else {
    image[maxWidth] = {
      alt: image.alt ?? '',
      src: image.url,
      layout: 'fill',
      objectFit: 'cover',
      className: `relative ` + className,
    };
  }

  return image[maxWidth];
};

export const calcDiscountStickerOffset = (product, image, imageHeight) => {
  if (product.pricing?.onSale) {
    const key = `discountStickerOffset.${imageHeight}`;
    if (!product[key]) {
      product[key] =
        Math.round((imageHeight / image.height / 2) * image.width) + 5;
    }
    return product[key];
  }
};
export const PRODUCT_PREVIEW_IMAGE_SIZE = { width: 302, height: 212 };
export const PRODUCT_SMALL_PREVIEW_IMAGE_SIZE = { width: 130, height: 130 };

export const PRODUCT_DETAIL_SMALL_IMAGE_SIZE = { width: 300, height: 240 };
export const PRODUCT_DETAIL_LARGE_IMAGE_SIZE = { width: 600, height: 400 };
export const WINE_DETAIL_LARGE_IMAGE_SIZE = { width: 200, height: 350 };
export const PRODUCT_ORDER_IMAGE_SIZE = { width: 60, height: 60 };

export const CAROUSEL_SINGLE_IMAGE_SIZE = { width: 700, height: 500 };
export const CAROUSEL_MULTI_IMAGE_SIZE = { width: 800, height: 320 };
export const TEASE_IMAGE_SIZE = { width: 288, height: 249 };
export const COLLECTION_DETAIL_LOGO_SIZE = { width: 400, height: 400 };
export const COLLECTION_PREVIEW_LOGO_SIZE = { width: 150, height: 150 };
export const PERSON_SIZE = { width: 337, height: 337 };
export const POST_PREVIEW_IMAGE_WIDTH = 450;
export const POST_DETAIL_IMAGE_WIDTH = 750;

export const calcProductImages = (product) => {
  product.images = product.images.slice(0, 1);
  product.images.forEach((i) => {
    calcImageProps(i, PRODUCT_PREVIEW_IMAGE_SIZE);
    calcDiscountStickerOffset(product, i, PRODUCT_PREVIEW_IMAGE_SIZE.height);
  });
};

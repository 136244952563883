import { VAT } from './config';

export const trackEvent = (props) => {
  props = { event: `ev-${props.category}`, label: '', value: 0, ...props };

  // if (ENV === 'development') {
  //     console.info('trackEvent', props);
  // }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(props);
  }
};

export const trackECommerce = (event, data, additionalAttrs = {}) => {
  const props = {
    event: `ec-${event}`,
    ecommerce: { [event]: data, ...additionalAttrs },
    category: 'ecommerce',
    action: event,
  };

  // if (ENV === 'development') {
  //     console.info('trackECommerce', event, props);
  // }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(props);
  }
};

export const convertProduct = (product, attrs = {}) => {
  return {
    name: product.name,
    id: product.id,
    price: product.preis,
    brand: product.brand,
    category: product.category.name,
    ...attrs,
  };
};

export const trackProductImpressions = (products, listName = undefined) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
  trackECommerce(
    'impressions',
    products.map((product, index) =>
      convertProduct(product, { list: listName, position: index + 1 }),
    ),
    { currencyCode: 'CHF' },
  );
};

export const trackProductClick = (product, listName = undefined) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
  trackECommerce('click', {
    actionField: { list: listName },
    products: [convertProduct(product)],
  });
};

export const trackProductDetail = (product) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#details
  trackECommerce('detail', {
    products: [convertProduct(product)],
  });
};

export const trackAddToCart = (product, quantity = 1) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
  trackECommerce(
    'add',
    {
      products: [convertProduct(product, { quantity })],
    },
    { currencyCode: 'CHF' },
  );
};

export const trackRemoveFromCart = (product, quantity = 1) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
  trackECommerce('remove', {
    products: [convertProduct(product, { quantity })],
  });
};

export const trackCheckoutStep = (checkoutLines, step) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
  trackECommerce('checkout', {
    actionField: { step: step },
    products: checkoutLines.map((l) =>
      convertProduct(l.variant.product, { quantity: l.quantity }),
    ),
  });
};

export const trackCheckoutOptions = (step, checkoutOption) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
  trackECommerce('checkout_option', {
    actionField: { step: step, option: checkoutOption },
  });
};

export const trackPurchase = (id, checkout) => {
  // https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
  const revenue = checkout.totalPrice.gross.amount;
  trackECommerce('purchase', {
    actionField: {
      id: id, // Transaction ID. Required for purchases and refunds.
      revenue: `${revenue}`, // Total transaction value (incl. tax and shipping)
      tax: `${revenue * VAT}`,
      shipping: `${checkout.shippingPrice.gross.amount}`,
    },
    products: checkout.lines.map((l) =>
      convertProduct(l.variant.product, { quantity: l.quantity }),
    ),
  });
};

/* eslint-disable */
/* @ts-ignore */
// Generated file by prismic-i18n.js
// Don't edit directly, it will be overwritten
const translations = {
    wine_package_size: 6,
'deguset_sku': '980000000',
free_shipping_cost_amount: 500,
'integrale_btn': 'Integrale',
'integrale_text': 'Alle unsere Weine können Sie auch via Integrale bestellen  und von den Vorteilen von Saviva profitieren.',
'integrale_url': 'https://www.integrale.ch/',
'edvinTitle': 'Gastronomie | Edvin Weine',
'edvinDescription': 'Finden Sie Ihre Weine bei uns. Entdecken Sie Neuheiten, lassen Sie sich inspirieren, nehmen Sie an Degustationen teil oder stöbern Sie durch unsere Newsartikel. Das Tor zur Weinwelt steht Ihnen hier offen. Einfach. Schnell. Kompetent.',
'out_of_stock': 'Aktuell nicht verfügbar',
'payment_error_title': 'Deine Zahlung konnte nicht verarbeitet werden',
'payment_declined': 'Dein Zahlungsmittel wurde abgelehnt. Falls du möchtest, kannst du es erneut versuchen.',
'payment_failed': 'Der Bezahlvorgang konnte nicht abgeschlossen werden. Falls Sie möchten, können Sie ihn erneut starten.',
'payment_canceled': 'Der Bezahlvorgang wurde abgebrochen. Falls Sie möchten, können Sie ihn erneut starten.',
'share': 'Teilen',
'menu': 'Menu',
'searchTitle': 'Suche nach Namen, Weintyp, Region, Traubensorte',
'shopTitle': 'Shop',
'blogTitle': 'News',
'bloglead': 'Updates aus der Edvin Weinwelt',
'eventsTitle': 'Events',
'ourAssortementTitle': 'Shop',
'profileTitle': 'Mein Profil',
'shippingCost': 'Versandkosten',
'copyright': '©2024 Edvin | Weinkellereien Aarau',
'produced': 'Produced by',
'contact': 'Kontakt',
'aboutUs': 'Über uns',
'newsletter': 'Newsletter',
'faq': 'FAQ',
'support': 'Support',
'dreamWine': 'Meine Traumweine finden',
'wishList': 'Merkliste',
'back': 'Zurück',
'allWine': 'Alle Weine',
'sales': 'Aktionen',
'contactThankYou': 'Vielen Dank für deine Nachricht. Wir melden uns bei dir.',
'pageNotFound': 'Ägsgüsi... Seite nicht gefunden.',
'backToHome': 'Weiter zur Startseite',
'addToCart': 'In den Warenkorb',
'addToWishlist': 'Zu Favoriten hinzufügen',
'removeFromWishlist': 'Aus Favoriten entfernen',
'discount': 'Gutschein/Rabatt',
'quantity': 'Anzahl',
'total': 'Total',
'pricePerphysischer-event': 'Preis pro Teilnahme',
'pricePerwein': 'Preis pro Flasche',
'pricePerallerlei': 'Preis pro Stück',
'pricePerpackage': 'Preis pro Set',
'pricePerevent': 'Preis pro Teilnahme',
'pricePergiftcard': 'Preis pro Gutschein',
'avilablity': 'Sofort verfügbar | Lieferung in 2-4 Tagen',
'cartDeliveryText': 'ab <strong>CHF {freeShippingCostAmount}.–</strong><br/>liefern wir Versandkostenfrei.',
'bottleSize': 'Flaschengrösse',
'grape': 'Traubensorte(n)',
'wineAging': 'Weinausbau',
'alcoholicContent': 'Alkoholgehalt',
'maturity': 'Trinkreife',
'temperature': 'Trinktemperatur',
'foodPairing': 'Der Wein passt zu',
'years': 'Jahre',
'rotweinTemperature': 'vorher 30 min. in den Kühlschrank stellen',
'allTemperature': 'Kühlschranktemperatur',
'wineDescription': 'Weinbeschrieb',
'tasteHistorgram': 'Weinstruktur',
'taste': 'Der Wein schmeckt nach',
'light': 'leicht',
'heavy': 'schwer',
'mild': 'mild',
'acidic': 'säurehaltig',
'velvety': 'samtig',
'strong': 'kräftig',
'aromaCross': 'Position im Aromakreuz',
'lovely': 'lieblich',
'sweet': 'süss',
'weissEarthyMineral': 'mineralisch',
'rotEarthyMineral': 'erdig',
'fruity': 'fruchtig',
'dry': 'trocken',
'readMore': 'mehr erfahren',
'relatedProducts': 'Ähnliche Weintypen',
'seeMore': 'Weitere anzeigen',
'redwiens': 'Rotweine',
'whitewiens': 'Weissweine',
'includedWine': 'Welche Weine sind im Set enthalten?',
'email': 'E-Mail',
'password': 'Passwort',
'login': 'Anmelden',
'expiredSession': 'Deine Session ist abgelaufen.',
'loginDescription': 'Melde dich bitte mit deinem Edvin Konto an.',
'required': 'Pflichtfeld',
'invalidEmail': 'Bitte überprüfe deine E-Mail-Adresse',
'invalidCredentials': 'Die eingegebenen Zugangsdaten sind leider nicht korrekt.',
'passwordReset': 'Passwort vergessen?',
'noProfile': 'Noch kein Edvin Konto? Jetzt registrieren. ',
'register': 'Registrieren',
'send': 'Abschicken',
'message': 'Nachricht',
'registerDescription': 'Erstellen Sie ein Profil für Ihren Betrieb, um Zugang zur inspirierenden Weinwelt von Edvin zu erhalten. Schön sind Sie bei uns.',
'alreadyAnAccount': 'Sie haben bereits ein Edvin-Profil?',
'data': 'Daten',
'yourProfile': 'Dein Geschmacksprofil',
'yourOrders': 'Deine Bestellungen',
'hello': 'Hallo',
'profileDescription': 'Willkommen in Ihrem Benutzerkonto. Hier können Sie Ihre Kontoinformationen verwalten, Ihr Geschmacksprofil erstellen und vieles mehr.',
'showAll': 'Alle anzeigen',
'homeTitle': 'Edvin Weine',
'homeTagline': 'Endlich Ihre Weine finden.',
'quizTeaserTitle': 'Welcher Weintyp sind Sie?',
'quizTeaserText': 'Finden Sie Ihren Wein-Geschmack!',
'quizTeaserButtonLabel': 'zum Quiz',
'adviceTeaserTitle': 'Welchen Wein soll ich kaufen?',
'adviceTeaserText': 'Finden Sie den passenden Wein.',
'adviceTeaserButtonLabel': 'zur Beratung',
'blogTeaserTitle': 'Endlich Wein verstehen',
'blogTeaserText': 'Einfach, klar und ungefiltert.',
'blogTeaserButtonLabel': 'zu den News',
'adviceResultHeading': 'Unsere Auswahl für Sie',
'newsletterThankYou': 'Vielen Dank für Ihre Anmeldung',
'toEventDetail': 'zum Event',
'firstName': 'Vorname',
'lastName': 'Nachname',
'confirmToc': 'Ich habe die <a href="/agb">Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiert',
'confirmAge': 'Ich bin mindestens 18 Jahre alt',
'toPostDetail': 'zum Beitrag',
'toBlog': 'zum Blog',
'otherArticles': 'Weitere Artikel',
'readDuration': 'min',
'filter': 'Filter',
'confirmTocError': 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen.',
'confirmAgeError': 'Bitte bestätigen Sie, dass Sie mindestens 18 Jahre alt sind.',
'quizTitle': 'WEINGESCHMACK',
'quizLead': 'Finden Sie Ihren Weingeschmack!',
'quizText': 'Beantworten Sie ein paar Fragen und finden Sie Ihren Wein, der Ihrem Geschmack entspricht.',
'quizButton': 'Quiz starten',
'quizQuestion1Text': 'Wie gehen Sie mit Salz um?',
'quizQuestion1MinLabel': 'Weniger ist mehr',
'quizQuestion1MaxLabel': 'Kippe es auf alles',
'quizQuestion2Text': 'Mögen Sie Zitrone in Ihrem Mineralwasser?',
'quizQuestion2MinLabel': 'Nein! Teufelszeugs',
'quizQuestion2MaxLabel': 'Zwingend!',
'quizQuestion3Text': 'Wie trinken Sie Ihren Lieblingskaffee/-tee?',
'quizQuestion3MinLabel': 'Schütte ihn komplett mit Milch und/oder Zucker zu.',
'quizQuestion3MaxLabel': 'Pur. Ohni nüt.',
'quizQuestion4Text': 'Wie steht es um Ihr Verhältnis mit Alkohol?',
'quizQuestion4MinLabel': 'Gsprützte Wiesse',
'quizQuestion4MaxLabel': 'Gin Tonic, Moscow Mule, Vodkaaa',
'quizQuestion5Text': 'Wie süss sind Sie?',
'quizQuestion5MinLabel': 'Ein 12-monatiger Zuckerdetox würde mir nichts ausmachen.',
'quizQuestion5MaxLabel': 'Könnte mich von Zucker ernähren, wenn es nicht tödlich wäre.',
'quizQuestion6Text': 'Mögen Sie Pilze?',
'quizQuestion6MinLabel': 'Überall hets Pilzli dra – Ich hasse da!',
'quizQuestion6MaxLabel': 'Ich wünsche mir, dass alles mit Pilzen paniert wäre.',
'quizQuestion7Text': 'Wie stehen Sie Früchten gegenüber?',
'quizQuestion7MinLabel': 'Ich zwinge mich aus gesundheitlichen Gründen dazu.',
'quizQuestion7MaxLabel': 'Ich liebe Früchte in allen Variationen: Roh bis Konfi.',
'quizResultTitle': 'Dein Quizergebnis',
'quizResultDescrption': 'Im Shop können Sie nach folgenden 2 Emblemen Ausschau halten oder gleich danach filtern.',
'quizReviewDescrption': 'Speicher Sie Ihr Ergebnis. Im Shop können Sie dann nach den folgenden 2 Emblemen Ausschau halten oder gleich danach filtern.',
'quizSaveResult': 'Ergebnis speichern',
'next': 'Weiter',
'red1Profile': 'Rot, leicht: Frische Rotweine mit einer höheren Säure machen Sie löcki, flöcki.',
'red2Profile': 'Rot, ausgewogen: Sie bevorzugen Ihren Rotwein ohne grosse Ecken und Kanten. Ein Smooth Criminal halt.',
'red3Profile': 'Rot, kräftig: Sie träumen nach Weinen mit Wucht (knackig Tannine und präsente Säure). Lassen Sie sich umhauen!',
'red4Profile': 'Rot, schwer: Ihre Rotweine müssen Fleisch am Knochen haben. Je mehr Wums (Alkohol, konzentrierte Frucht), desto besser.',
'white1Profile': 'Weiss, leicht: Ihre Welt ist mit Weissweinen, die eine tiefere Säure haben und keine Fruchtbomben sind, komplett in Ordnung. Balance Baby!',
'white2Profile': 'Weiss, ausgewogen: Knackig, frische Weissweine mit einer eher höheren Säure beleben Ihre Seele.',
'white3Profile': 'Weiss, expressiv: Sie haben nichts gegen Aromabomben. Du mögen Frucht, Säure, ein wenig Süsse. Die volle Ladung!',
'white4Profile': 'Weiss, süsslich: sie sind ein Schleckmaul. Ein wenig Restsüsse im Wein und Sie sind happy.',
'yourTasteHeading': 'Weine nach Ihrem Geschmack',
'yourTasteDescription': 'Diese Weine entsprechen Ihrem Weintyp',
'showAllMyWineTypes': 'Alle meine Weine anzeigen',
'savedWines': 'Favoriten',
'recommendedWines': 'Traumweine',
'posts': 'Blogs',
'blog': 'Blogs',
'events': 'Events',
'wines': 'Weine',
'wein': 'Wein',
'packages': 'Selection Sets',
'applyFilter': 'Resultate anzeigen',
'applyfiltersingular': 'Resultat anzeigen',
'winetype': 'Weintyp',
'countries': 'Länder',
'reset': 'zurücksetzen',
'otherSearchCriteria': 'Weitere Suchkriterien',
'resultSort': 'Sortierung',
'invalidPostalCode': 'Ungültige Postleitzahl',
'choosePaymentType': 'Wähle eine der verfügbaren Zahlungsoptionen.',
'paymentStepLogin': 'Anmelden',
'paymentStepAddress': 'Adresse',
'paymentStepPaymentType': 'Zahlung',
'paymentStepOverview': 'Übersicht',
'paymentStepDone': 'Vielen Dank Ihnen.',
'address': 'Adresse',
'addressText': 'Wohin dürfen wir dir deine Bestellung senden?',
'shippingAddress': 'Lieferadresse',
'billingAddress': 'Rechnungsadresse',
'useSameAddress': 'Entspricht meiner Rechnungsadresse',
'specifyAddress': 'Eine andere Rechnungsadresse auswählen',
'continue': 'Weiter',
'companyName': 'Firma (optional)',
'streetAddress1': 'Strasse und Hausnummer',
'streetAddress2': 'Adresszusatz (optional)',
'postalCode': 'Postleitzahl',
'city': 'Ort',
'payment': 'Zahlung',
'paymentType': 'Zahlungsart',
'paymentOptions': 'Optionen',
'creditCard': 'Kreditkarte',
'creditCardDesc': 'Unterstützt werden Visa, MasterCard und American Express.',
'creditCardNumber': 'Kartennummer',
'creditCardExpiration': 'MM / JJ',
'creditCardCvv': 'CVV/CVC',
'paymentInProgress': 'Zahlung wird verarbeitet…',
'goToPayment': 'Weiter zur Zahlung',
'reviewOrder': 'Bestellübersicht',
'reviewText': 'Überprüfen Sie im letzten Schritt Ihre Bestellung.',
'twint': 'TWINT',
'twintDesc': 'Bezahle bequem und sicher direkt in der App von TWINT.',
'promoCode': 'Gutschein oder Rabattcode hinzufügen',
'promoCodeDesc': 'Gutschein- / Rabattcode',
'redeemPromoCode': 'Einlösen',
'subtotal': 'Zwischensumme',
'shippingCosts': 'Versand',
'chooseLoginText': 'Melden Sie sich bitte mit Ihrem Edvin Konto an.',
'continueAsGuest': 'Als Gast fortfahren',
'buyNow': 'Jetzt kaufen',
'edit': 'Bearbeiten',
'done': 'Vielen Dank für Ihren Einkauf.',
'doneText': 'Sie erhalten eine Bestellbestätigung per E-Mail.',
'cart': 'Warenkorb',
'emptyCartMessage': 'In Ihrem Warenkorb befinden sich derzeit noch keine Artikel.',
'goToShop': 'Zum Shop',
'goToCheckout': 'Zur Kasse',
'adviceIntro': 'WEINANLASS',
'adviceLead': 'Keine Ahnung welchen Wein Sie kaufen sollst? Wir helfen Ihnen den passenden Wein zu finden.',
'adviceDescrption': 'Beantworten Sie ein paar Fragen und Sie sind für jede Situation bestens gewappnet.',
'adviceButtonStart': 'Beratung starten',
'forward': 'Weiter',
'eg': 'Egal',
'anlassHeading': 'Aus welchem Anlass möchten Sie Wein kaufen?',
'geschenk': 'Geschenk',
'essen': 'Essen',
'apero': 'Apéröle',
'feiern': 'Wir feiern!',
'feiernHelpText': '«Es dörf also öbbis choste»',
'mich': 'Für mich',
'geschenkHeading': 'Was für ein Geschenk soll es sein?',
'mitbringsel': 'Kleines Mitbringsel',
'gedanke': 'Der Gedanke zählt',
'hermachen': 'Es soll etwas hermachen',
'kosten': 'Es döf echli öpis choschte',
'signout': 'Abmelden',
'gunstig': '«Günstig & Gäbig»',
'gunstigHelpText': 'CHF 5.- bis CHF 20.-',
'mitte': '«Die goldigi Mitti»',
'mitteHelpText': 'CHF 20.- bis CHF 40.-',
'gonnen': '«Gönn dir»',
'gonnenHelpText': 'Ab CHF 40.-',
'budgetHeading': 'Was ist Ihr Budget?',
'headingStyle': 'Welchen Weinstil möchten Sie kaufen?',
'rotwein': 'Rotwein',
'weisswein': 'Weisswein',
'rose': 'Rosé',
'schaumwein': 'Perl- & Schaumwein',
'uberraschung': 'Überrasch mich!',
'headingLand': 'Welche Länder sollen in die Auswahl?',
'schweiz': 'Schweiz',
'italien': 'Italien',
'spanien': 'Spanien',
'frankreich': 'Frankreich',
'osterreich': 'Österreich',
'argentinien': 'Argentinien',
'portugal': 'Portugal',
'sudafrika': 'Südafrika',
'commingSoonHeading': 'Diese Seite ist noch nicht bereit.',
'commingSoonDescription': 'Sie sind schneller als wir, aber die Seite wird bald ready sein.',
'headingGrosse': 'Welche Flaschengrösse darfs sein?',
'headingZutaten': 'Mit welchen Zutaten werden Sie hauptsächlich kochen?',
'salt': 'Salz, Pfeffer, Öl',
'mediteran': 'Mediterran',
'mediteranHelpText': 'z.B. Basilikum, Thymian, Rosmarin',
'sharf': 'Scharf (Chili)',
'creme': 'Crème, käsig',
'gewurzkasten': 'Ganzer Gewürzkasten',
'gewurzkastenHelpTtext': 'z.B. Muskatnuss, Kardamom, Kurkuma, Kümmel',
'headingPersoanl': 'Ihr persönlicher Anlass?',
'entspannen': 'Entspannen',
'dateNight': 'Date Night',
'einfachSo': 'Einfach so',
'headingFarbe': 'Welchen Weinstil möchten Sie kaufen?',
'headingFreunde': 'Für welchen Anlass',
'familienfete': 'Familienfete',
'friends': 'Zäme mit Fründe',
'dinnerForOne': 'Dinner for 1',
'UNIQUE': 'Diese Email wird bereits verwendet',
'INVALID': 'Ungültig',
'INVALID_CREDENTIALS': 'Die eingegebenen Zugangsdaten sind nicht korrekt',
'PASSWORD_TOO_SHORT': 'Das Passwort ist zu kurz (mindestens 5 Zeichen)',
'Please, enter valid credentials': 'Die eingegebenen Zugangsdaten sind nicht korrekt',
'genericError': 'Leider ist ein Fehler aufgetreten.',
'reload': 'Seite neu laden',
'tryAgain': 'Bitte versuchen Sie es erneut.',
'contactSupport': 'Falls es nicht klappt, kontaktiere bitte',
'checkoutError': 'Fehler',
'red1': 'rot, leicht',
'red2': 'rot, ausgewogen',
'red3': 'rot, kräftig',
'red4': 'rot, schwer',
'white1': 'weiss, leicht',
'white2': 'weiss, ausgewogen',
'white3': 'weiss, expressiv',
'white4': 'weiss, süsslich',
'cartAdded': 'Zum Warenkorb hinzugefügt',
'show': 'Anzeigen',
'ratingDetailHeading': 'Deguwein bewerten',
'rateHeading': 'Willkommen zur Weinbewertung!',
'rateQuizHeading': 'Mit dem Quiz deinen Weintyp bestimmen.',
'rateQuizDescription': 'Beantworte uns neben den Bewertungen noch ein paar Fragen und wir finden deinen Traumwein garantiert.',
'rateDesription': 'Sag uns, ob dir die Weine aus dem Startert-Deguset gefallen oder nicht. Ganz ehrlich, ohne zu flunkern, völlig unverfroren! Nur deine eigene Meinung zählt.',
'ratingPreviewHeading': 'Du bist deinem Traumwein einen Schritt näher',
'ratingPreviewDescription': 'Juhuii! Du hast das Quiz und die Bewertungen des Degusets abgeschlossen! Madelyne kümmert sich nun persönlich um dein Ergebnis. Und schon bald können wir dir genau sagen, welche Weine dir zu 100% schmecken werden! Das erkennst du dann an diesem goldenen Pokal-Badge – alle Weine die das haben, sind deine Traumweine. Du wirst in wenigen Tagen eine E-Mail erhalten. Später wird das Ergebnis auch in deinem Profil sichtbar sein.',
'ratingDetailRateHeading': 'Wie schmeckt dir dieser Wein?',
'ratingDetailRateMinLabel': 'Mag ich gar nicht',
'ratingDetailRateMaxLabel': 'Genau mein Ding',
'ratingDetailRateC2A': 'Übernehmen',
'toWineDescription': 'zur weinbeschreibung',
'notFoundUpselling': 'Vielleicht finden Sie hier etwas',
'submitRaing': 'Bewertungen abschliessen',
'resultStored': 'Ihr Ergebnis wurde gespeichert.',
'pwResetError': 'Fehler, bitte versuchen Sie es neu.',
'pwResetHeading': 'Passwort zurücksetzen',
'pwResetConfirmation': 'Wir haben Ihnen eine E-Mail mit den nötigen Schritten gesendet (Bitte überprüfen Sie auch den Spam Ordner)',
'pwResetDescription': 'Bitte die E-Mail-Adresse verwenden, welche Sie am häufigsten benutzen.',
'setPwHeading': 'Neues Passwort setzten',
'setPwConfirmPassword': 'Passwort bestätigen',
'setPwConfirmation': 'Sie können sich nun mit Ihrem neuen Passwort anmelden.',
'passwordDoNotMatch': 'Die Passwörter stimmen nicht überein',
'pwMinLen': 'Das Passwort muss mindestens 5 Zeichen lang sein',
'setPwNewTokenRequired': 'Leider ist die Frist für das Zurücksetzten abgelaufen. Fordern Sie bitte ein neues E-Mail an.',
'setPwGetNewToken': 'Anfordern',
'setPwGetChangeEmail': 'Unbekannte E-Mail-Adresse. Bitte neu Anfordern.',
'save': 'Speichern',
'deleteAccount': 'Konto Löschen',
'shippingAndInvoiceAddress': 'Liefer- und Rechnungsadresse',
'changesWithSaved': 'Ihre Änderungen wurden übernommen (Die neue E-Mail-Adresse müssen Sie noch bestätigen).',
'changesSaved': 'Ihre Änderungen wurden übernommen.',
'noOrders': 'Sie haben noch keine Bestellungen.',
'DRAFT': 'Entwurf',
'UNFULFILLED': 'Offen',
'PARTIALLY_FULFILLED': 'Teilweise abgeschlossen',
'FULFILLED': 'Abgeschlossen',
'CANCELED': 'Storniert',
'country': 'Land',
'confirmWithPw': 'Änderungen mit aktuellem Passwort bestätigen.',
'errorWhileSaving': 'Fehler beim Speichern.',
'errorWhileSavingWrongPw': 'Fehler beim Speichern. Stimmt das Passwort?',
'emailConfirmed': 'Danke! Ihre Email wurde bestätigt.',
'emailConfirmationHeading': 'Email bestätigen',
'confirm': 'Bestätigen',
'changeEmail': 'Um die neue E-Mail Adresse zu bestätigen müssen Sie sich zuerst mit dem bisherigen Edvin Profil anmelden.',
'useNewEmail': 'Ihre E-Mail Adresse wurde geändert. Sie können sich nun mit der neuen Adresse anmelden.',
'deleteAccountConfirmation': 'Wir werden Sie vermissen. Wir haben Ihnen eine E-Mail mit den nötigen Informationen gesendet.',
'confirmDeletion': 'Möchten Sie Ihr Edvin-Profil wirklich unwiederbringlich löschen?',
'accountDeleteHeading': 'Edvin-Profil löschen',
'accountDeleteLoginDescription': 'Um Ihr Edvin-Profil zu löschen müssen Sie sich zuerst anmelden.',
'accountDeleteButton': 'Edvin-Profil unwiederbringlich löschen.',
'accountDeleted': 'Ihr Edvin-Profil wurde gelöscht.',
'misc': 'Geschenke',
'deguset_teaser_heading': '<h2>Traumweine garantiert</h2>Edvin erstellt dir dein persönliches Geschmacksprofil, zeigt dir deine Traumweine und verspricht dir dadurch 100%-igen Weingenuss.',
'answer': 'Deine Antwort',
'competitionthankyou': 'Vielen Dank für deine Antwort',
'profiledreamwinetitle': 'Deine Traumweine',
'profiledreamwinedescription': 'Wir können dir genau sagen, welche Weine dir zu 100% schmecken werden! Das erkennst du an diesem goldenen Pokal-Badge – alle Weine die das haben, sind deine Traumweine. Viel Spass beim Entdecken!',
'yourdreamwinedescription': 'Unsere Empfehlungen für dich',
'dreamwinenotfittitle': 'Du findest wir haben deinen Geschmack nicht getroffen oder dieser hat sich geändert?',
'dreamwinenotfitdescription': 'Dann kontaktiere uns und wir schauen gemeinsam an was es liegt.',
'dreamwinenotfitc2a': 'kontakt aufnehmen',
'dreamwinenowhitewinettile': 'Du magst keinen Weisswein',
'dreamwinenowhitewinedescription': 'Du weisst schon ziemlich genau was dir schmeckt und was nicht. Klar ist, dass du Weissweine gar nicht magst. Dein Rotwein-Figürli begleitet dich aber treu auf deiner Entdeckungsreise.',
'dreamwinenoredwinetitle': 'Du magst keinen Rotwein',
'dreamwinenoredwinedescription': 'Du weisst schon ziemlich genau was dir schmeckt und was nicht. Klar ist, dass du Rotweine gar nicht magst. Dein Weisswein-Figürli begleitet dich aber treu auf deiner Entdeckungsreise.',
'subscribenewsletter': 'Ich möchte über Neuigkeiten von Edvin informiert werden',
'matchesyourtaste': 'trifft Ihren Geschmack.',
'soldout': 'Ausverkauft',
'paeckli': 'Selection Sets',
'allerlei': 'Geschenke',
'winecalculatortitle': 'Wieviel Wein soll es sein?',
'calculate': 'Berechnen',
'winecalculatortext': 'Der Weinrechner erleichtert Ihnen die Planung und zeigt Ihnen, wie viele Flaschen Wein Sie ungefähr benötigen.',
'recalculate': 'Neu berechnen',
'nbrguests': 'Anzahl Gäste',
'nbrglasses': 'Gläser pro Stunde pro Gast',
'partyduration': 'Dauer der Party in Stunden',
'winecalculatorresult': 'Sie benötigen total',
'bottle': 'Flasche',
'bottles': 'Flaschen',
'suggestnonealcoholic': 'Für die Autofahrer:innen und Nichttrinker:innen unter ihnen, empfehlen wir die feinen alkoholfreien Weine.',
'cart_free_shipping_small_amount_missing': 'Ihnen fehlen noch <strong>CHF {missingAmount}.–</strong> für eine kostenlose Lieferung.',
'cart_sustainable_hint_free_shipping_cost_hint': 'Fülle deine Bestell-Box auf <strong>6 Flaschen</strong> auf.<p class="mt-2">Das ist Nachhaltiger und ab einem Bestellwert von <strong>CHF {freeShippingCostAmount}.–</strong> liefern wir Versandkostenfrei.</p>',
'cart_free_shipping_reached': 'Wir schenken Ihnen die Versandkosten.',
'cart_sustainable_hint': 'Juhu, wir schenken dir die Versandkosten.<p class="mt-2">Sorgst du dich um Nachhaltigkeit?<br/>Dann fülle deine Bestell-Box auf <strong>6 Flaschen</strong> auf.<p>',
'packages_3er': '3er Selection Sets',
'packages_6er': '6er Selection Sets',
'wine_from': 'Wein von',
'collection': 'Kollektionen',
'weingueter': 'Weingüter',
'weinguetertitle': 'Weingüter',
'promocodeadded': 'Gutschein/Rabattcode hinzugefügt',
'invalidpromocode': 'Ungültiger Gutschein oder Rabattcode',
'weingueterlead': 'Unser Sortiment umfasst exklusive Weine aus der Schweiz, Europa und Übersee.',
'majority_finds': 'Mehrheit findet',
'hello_text': 'Endlich einfach Weine finden.',
'customer_reviews': 'Kundenmeinungen',
'ratings': 'Bewertungen',
'rate_wine': 'Wein bewerten',
'all_ratings': 'Alle Bewertungen',
'less_ratings': 'Weniger Bewertungen',
'NOT_FOUND': 'Nichts gefunden',
'social_auth_title': 'Mit bestehendem Account anmelden',
'abort': 'Abbrechen',
'rating_authentication_required': 'Melde dich an um die Bewertung abzuschliessen',
'complete': 'Account vervollständigen',
'review_change_text': 'Du kannst deine Meinung jederzeit ändern.',
'finish_signup_heading': 'Anmeldung vervollständigen',
'finish_signup_description': 'Wir benötigen noch Angaben um deine Anmeldung abzuschliessen',
'taste_authentication_required': 'Melden Sie sich an, um zu speichern.',
'social_auth_not_linked_title': 'Diese Email wird bereits verwendet.',
'social_auth_not_linked_message': 'Es existiert bereits ein Account mit der verwendeten E-Mail-Adresse. Bitte melden Sie sich an um diese zu ändern.',
'social_auth_google': 'Mit Google anmelden',
'social_auth_facebook': 'Mit Facebook anmelden',
'free_delivery': 'Gratislieferung ab CHF 500.-',
'delivery_period': 'Morgen geliefert',
'in_stock': 'Auf Lager',
'price_per_wein_quantity_singular': 'für eine Falsche',
'price_per_wein_quantity': 'für {quantity} Flaschen',
'price_per_allerlei_quantity_singular': 'für ein Stück',
'price_per_allerlei_quantity': 'für {quantity} Stück',
'price_per_package_quantity_singular': 'für ein Set',
'price_per_package_quantity': 'für {quantity} Sets',
'price_per_event_quantity_singular': 'für eine Teilnahme',
'price_per_event_quantity': 'für {quantity} Teilnahmen',
'price_per_giftcard_quantity_singular': 'für einen Gutschein',
'price_per_giftcard_quantity': 'für {quantity} Gutscheine',
'price_per_physischer-event_quantity_singular': 'für eine Teilnahme',
'price_per_physischer-event_quantity': 'für {quantity} Teilnahmen',
'banner_delivery': 'Dein Kontakt: +41 62 838 00 30 I bestellungen@edvinweine.ch',
'package_suggestion_title': 'Wunsch Set',
'package_suggestion_text': 'Schreibe uns zu welchem Anlass oder Gericht du passende Weine suchst. Wir stellen sie dir gerne zusammen. ',
'package_suggestion_cta': 'Jetzt wünschen',
'quantity_discount': '10% Mengenrabatt ab 30 Flaschen der gleichen Sorte. ',
'search_wein': 'Weine',
'search_paeckli': 'Selection Sets',
'search_allerlei': 'Geschenke',
'search_events': 'Events',
'search_blog': 'News-Beiträge',
'search_collection': 'Sammlungen',
'search_weingueter': 'Weingüter',
'at-latest': 'bis spätestens',
'after': 'erst ab',
'at': 'genau am',
'asap': 'So schnell wie möglich',
'limited-number-allowed': 'Von diesem Wein können maximal 6 Flaschen bestellt werden.',
'b2b-address-info': 'Änderung der Lieferadresse bitte an  <a href="mailto:info@edvinweine.ch">info@edvinweine.ch</a> mitteilen. Danke!',
'wait-list-title': 'Vielen Dank für Ihre Registration. ',
'wait-list-text': 'Sie erhalten innerhalb 24h eine Email von uns mit der Freischaltung Ihres Kontos. Besten Dank für Ihre Geduld und herzlich willkommen bei Edvin.',
'tax': 'MwSt.',
'totalexcltax': 'Gesamttotal (exkl. MwSt.)',
'totalInclTax': 'Gesamttotal (inkl. MwSt.)',
'download-flyer': 'Als PDF herunterladen',
'downloading-flyer': 'Als PDF herunterladen ...',
'generating-flyer': 'PDF erstellen ...',
'flyer-desktop-hint': 'PDF ist nur verfügbar für Bildschirmgrössen über 1200px',

    emptyWishlistMessage: (things) =>
`Du hast zur Zeit keine gemerkten ${things}.`,
showAllOfType: (type) => `Alle ${type} anzeigen`,
cartContents: (numArticles, formattedPrice) => `${numArticles} Artikel` + (formattedPrice ? ` (${formattedPrice})` : ''),
};
global.missing = global.missing || {};
export const t = (key, variables) => {
    if (global.missing && !Object.keys(translations).includes(key)) {
        global.missing[key] = true;
    }
    if (typeof variables == 'undefined') {
        return translations[key] ?? key;
    }

    let result = translations[key] ?? key;

    for (var prop in variables) {
        if (Object.prototype.hasOwnProperty.call(variables, prop)) {
            result = result.replace(`{${prop}}`, variables[prop]);
        }
    }

    return result;
};
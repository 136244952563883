import { mutate } from 'swr';
import { ERROR, showToast } from '../components/toast/toast-context';
import { t } from '../generated/i18n';
import { DUMMY_EMAIL, getDummyAddress } from './address';
import { executeGQL } from './api';
import { logError } from './error';
import { getProductsByIds } from './product';
import { extract, selectFields, unique, withRetry } from './utils';
import { IS_DEFAULT_CHANNEL, PRICE_FIELD } from './config';

const ADDRESS_INPUT_FIELDS = [
  'firstName',
  'lastName',
  'phone',
  'companyName',
  'streetAddress1',
  'streetAddress2',
  'postalCode',
  'city',
  'country',
];

export const fetchCheckoutData = async ({
  checkoutToken,
  clearCheckoutToken,
}) => {
  if (!checkoutToken) return undefined;
  const response = await executeGQL('checkout', {
    token: checkoutToken,
  });

  // silent fail
  if (response.hasErrors) {
    return undefined;
  }

  if (!response.checkout?.id) {
    clearCheckoutToken(undefined);
  }

  return await updateCheckout(response.checkout);
};

const withRetryOnError = (fn) =>
  withRetry(
    async (...args) => {
      try {
        const response = await fn(...args);
        if ((response.errors?.length ?? 0) > 0) {
          logError(`RPC errors: ${response.errors.join(', ')}`);
        }
        return response;
      } catch (e) {
        logError(e);
        return undefined;
      }
    },
    {
      shouldRetry: (response) =>
        !response || (response.errors?.length ?? 0) > 0,
    },
  );

export const getCheckoutSwrKey = (token) => `checkout-${token}`;

const reportSwrMutation = async ({ checkout, computeClientFields = true }) => {
  if (checkout?.token) {
    if (computeClientFields) {
      checkout = await updateCheckout(checkout);
    }
    mutate(getCheckoutSwrKey(checkout.token), checkout, false);
  }
};

export const createCheckout = withRetry(async (user) => {
  const email = user?.email ?? DUMMY_EMAIL;
  const response = await executeGQL('checkoutCreate', {
    email,
  });
  const errors = extractErrors(response);
  if (errors.length > 0 || !response?.final?.checkout?.token) {
    return { errors };
  }
  reportSwrMutation({ checkout: response?.final?.checkout });

  return {
    success: true,
    checkout: response.final.checkout,
    token: response.final.checkout.token,
  };
});

const updateCheckout = async (checkout) => {
  if (checkout && checkout.lines) {
    const emptyLines = checkout.lines?.reduce((acc, line) => {
      if (!line?.variant?.product) {
        acc.push(line.id);
      }
      return acc;
    }, []);

    if (emptyLines.length > 0) {
      let resp;
      for (var i = 0; i < emptyLines.length; i++) {
        resp = await executeGQL('checkoutDeleteLine', {
          checkoutId: checkout.id,
          lineId: emptyLines[i],
        });
        checkout = resp.checkout;
      }
    }

    const pids = checkout.lines?.map((line) => line?.variant?.product.id);
    const data = await getProductsByIds(pids);
    const dict = data.results
      ? data.results.reduce((acc, p) => {
          acc[p.id] = p;
          return acc;
        }, {})
      : {};

    checkout.lines?.forEach((line) => {
      line.variant.product = dict[line?.variant?.product.id];
    });

    if (checkout.totalItemsCount == null) {
      checkout.totalItemsCount =
        checkout.lines?.reduce((acc, line) => acc + line.quantity, 0) ?? 0;
    }
  }
  return checkout;
};

const showUpdateLinesError = (message = '') => {
  logError(`Showing critical error '${message}'.`);

  if (message.indexOf('Cannot add more than') > -1) {
    // @ts-ignore
    showToast(t('limited-number-allowed'), {
      type: ERROR,
      timeout: 8000,
    });
  } else {
    // @ts-ignore
    showToast(t('genericError'), {
      actionLabel: t('reload'),
      action: () => location.reload(),
      type: ERROR,
      timeout: 8000,
    });
  }
};

export const updateLines = withRetryOnError(async ({ checkout, lines }) => {
  let accumulatedLineMutations = {};

  accumulatedLineMutations[checkout.id] = [
    ...lines,
    ...(accumulatedLineMutations[checkout.id] ?? []),
  ];

  let currentCheckout;
  const removeDuplicates = (lines) => {
    const seen = {};
    return lines.filter((line) => {
      if (seen[line.variantId]) return false;
      seen[line.variantId] = true;
      return true;
    });
  };
  for (let [checkoutId, lines] of Object.entries(accumulatedLineMutations)) {
    lines = removeDuplicates(lines);
    const response = await executeGQL('checkoutLinesUpdate', {
      id: checkoutId,
      lines,
    });

    const errors = extractErrors(response);
    if (errors.length > 0) {
      showUpdateLinesError(
        `CheckoutApi.actuallyUpdateLines failed (${JSON.stringify(errors)})`,
      );
    } else {
      currentCheckout = response?.final?.checkout;
    }
  }

  reportSwrMutation({ checkout: currentCheckout });

  if (currentCheckout) {
    if (!currentCheckout.shippingAddress) {
      const addressResp = await setCheckoutAddresses({
        checkout: currentCheckout,
        email: DUMMY_EMAIL,
        billingAddress: getDummyAddress(),
        shippingAddress: getDummyAddress(),
        useSameAddress: true,
      });

      currentCheckout = addressResp.checkout
        ? addressResp.checkout
        : currentCheckout;
    }

    const nextShippingMethod =
      currentCheckout.availableShippingMethods?.find(
        (m) => m.name.indexOf("Delivery") === 0,
      ) ||
      currentCheckout.availableShippingMethods?.find(
        (m) => m.price.amount == 0,
      );

    if (
      nextShippingMethod &&
      !(
        currentCheckout.shippingAddress &&
        currentCheckout.shippingAddress.id === nextShippingMethod.id
      )
    ) {
      await setCheckoutShippingMethod({
        checkout: currentCheckout,
        shippingMethodId: nextShippingMethod.id,
      });
    }

    if (!IS_DEFAULT_CHANNEL) {
        if (currentCheckout.totalPrice[PRICE_FIELD]?.amount > 2000) {
          await setCheckoutPromoCode({checkout: currentCheckout, promoCode: 'b2b-2000'})
        } else if (currentCheckout.totalPrice[PRICE_FIELD]?.amount > 1500) {
          await setCheckoutPromoCode({checkout: currentCheckout, promoCode: 'b2b-1500'})
        } else if (currentCheckout.totalPrice[PRICE_FIELD]?.amount > 1000) {
          await setCheckoutPromoCode({checkout: currentCheckout, promoCode: 'b2b-1000'})
        }
      }

  }

  return { success: true };
});

export const setCheckoutAddresses = withRetryOnError(
  async ({
    checkout,
    email,
    shippingAddress,
    billingAddress,
    useSameAddress,
    ...metaData
  }) => {
    let operation = 'checkoutSetAddresses';
    const parameters = {
      id: checkout.id,
      email,
      shippingAddress: selectFields(
        { ...shippingAddress, country: 'CH' },
        ADDRESS_INPUT_FIELDS,
      ),
      billingAddress: selectFields(
        { ...billingAddress, country: 'CH' },
        ADDRESS_INPUT_FIELDS,
      ),
      useSameAddress,
      ...metaData,
    };

    const isDigitalOnly = checkout.lines?.every(
      (l) => l?.variant?.product?.productType?.isDigital,
    );

    if (isDigitalOnly) {
      operation = 'checkoutSetAddressesNoShipping';
      delete parameters.shippingAddress;
      // @ts-ignore
      parameters.isDigitalOnly = true;
    }

    const response = await executeGQL(operation, parameters);

    const errors = extractErrors(response);
    if (errors.length > 0) {
      return { errors };
    }

    reportSwrMutation({ checkout: response?.final?.checkout });
    return { success: true, checkout: response?.final?.checkout };
  },
);

export const setCheckoutPromoCode = withRetryOnError(
  async ({ checkout, promoCode }) => {
    const response = await executeGQL('checkoutAddPromoCode', {
      id: checkout.id,
      promoCode,
    });

    if (response.hasErrors) {
      return { errors: response.errors };
    }
    reportSwrMutation({ checkout: response.result });
    return { success: true };
  },
);

export const setCheckoutShippingMethod = withRetryOnError(
  async ({ checkout, shippingMethodId }) => {
    const response = await executeGQL('checkoutShippingMethodUpdate', {
      id: checkout.id,
      shippingMethodId: shippingMethodId,
    });

    if (response.hasErrors) {
      return { errors: response.errors };
    }

    reportSwrMutation({ checkout: response.result });
    return { success: true };
  },
);

const extractErrors = (responses) =>
  unique(
    Object.values(responses)
      .map((r) => r.checkoutErrors ?? r.errors ?? r.paymentErrors ?? [])
      .reduce((a, b) => [...a, ...b], [])
      .map(extract('message')),
  );

import useSWR from 'swr';
import { getProductsByIds } from './product';

function getData(ids, type) {
  const swr = useSWR([`wishlist:${type}`, ids.join(' ')], () => {
    if (ids.length == 0) {
      return { result: [], resultCount: 0 };
    }
    return getProductsByIds(ids);
  });
  return { count: ids.length, swr };
}

export function loadWishlistContents(wishlist) {
  return {
    wines: getData(wishlist?.wein ?? [], 'wein'),
    packages: getData(wishlist?.set ?? [], 'set'),
    posts: getData(wishlist?.post ?? [], 'post'),
    events: getData(wishlist['physischer-event'] ?? [], 'physischer-event'),
    misc: getData(wishlist?.allerlei ?? [], 'allerlei'),
  };
}

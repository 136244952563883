import { useState } from 'react';
import { getData, removeData, setData } from './storage';

export function usePersistentState({ initialValue = undefined, storageKey }) {
  const [get, set] = useState(getData(storageKey) ?? initialValue);
  return [
    get,
    (value) => {
      setData(storageKey, value);
      set(value);
    },
    () => {
      set(initialValue);
      removeData(storageKey);
    },
  ];
}

import { createContext } from 'react';
import { usePersistentState } from '../../lib/persistent-state';
import { getDataLegacy, setData } from '../../lib/storage';
import { trackEvent } from '../../lib/tracking';
import { loadWishlistContents } from '../../lib/wishlist';

// @ts-ignore
export const WishlistContext = createContext({});

const useWishlistState = () => {
  const [wishlist, setWishlistInternal] = usePersistentState({
    storageKey: 'wishlist',
    initialValue: getDataLegacy('edvinWishlist', {}),
  });

  const setWishlist = (wishlist) => {
    setData('wishlist', wishlist);
    setWishlistInternal({ ...wishlist });
  };

  return { wishlist, setWishlist };
};

const appendUnique = (list, el) => (list.includes(el) ? list : [...list, el]);
const remove = (list, el) => list.filter((x) => x != el);

const WishlistContextProvider = ({ children }) => {
  const { wishlist, setWishlist } = useWishlistState();

  const addToWishlist = ({ type, id }) => {
    trackEvent({
      category: 'whishlist',
      action: 'add',
      label: type,
    });
    setWishlist({
      ...wishlist,
      [type]: appendUnique(wishlist[type] || [], id),
    });
  };

  const removeFromWishlist = ({ type, id }) => {
    trackEvent({
      category: 'whishlist',
      action: 'remove',
      label: type,
    });
    setWishlist({
      ...wishlist,
      [type]: remove(wishlist[type] || [], id),
    });
  };

  const isInWishlist = ({ type, id }) => !!wishlist?.[type]?.includes(id);

  const clearWishlist = () => setWishlist({});

  const getWishlistContents = () => loadWishlistContents(wishlist);

  return (
    <WishlistContext.Provider
      value={{
        addToWishlist,
        removeFromWishlist,
        clearWishlist,
        isInWishlist,
        totalItemsCount: Object.keys(wishlist).reduce(
          (a, k) => a + wishlist[k].length,
          0,
        ),
        getWishlistContents,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistContextProvider;

export const getDefaultAddress = (initial = {}) => ({
  firstName: '',
  lastName: '',
  companyName: '',
  phone: '',
  streetAddress1: '',
  streetAddress2: '',
  postalCode: '',
  city: '',
  country: 'CH',
  ...initial,
});

export const DUMMY_EMAIL = 'guest@edvin.rocks';

export const getDummyAddress = () =>
  getDefaultAddress({
    postalCode: 5000,
    city: 'Aarau',
    streetAddress1: 'Rohrerstrasse 64',
    firstName: 'Weinkellereien',
    lastName: 'Aarau AG',
  });

import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { t } from '../generated/i18n';
import { executeGQL } from '../lib/api';
import {
  createCheckout,
  fetchCheckoutData,
  getCheckoutSwrKey,
  updateLines,
} from '../lib/checkout-api';
import { BASE_URL, IS_DEFAULT_CHANNEL } from '../lib/config';
import { logError } from '../lib/error';
import { usePersistentState } from '../lib/persistent-state';
import { consumeData, getData, getDataLegacy } from '../lib/storage';
import { trackAddToCart, trackRemoveFromCart } from '../lib/tracking';
import { CartOverlayContext } from './cart/cart-overlay';

// @ts-ignore
export const UserContext = createContext();

const UserContextProvider = (props) => {
  const router = useRouter();
  const { status, data: session } = useSession();
  const [user, setUser] = useState(undefined);
  const [showBanner, setShowBanner] = useState(t('enable_banner'));
  const overlayContext = useContext(CartOverlayContext);

  const [checkoutToken, setCheckoutToken, clearCheckoutToken] =
    usePersistentState({
      storageKey: 'checkoutToken',
      initialValue: getDataLegacy('edvinCheckoutToken'),
    });

  const [
    authenticatedActions,
    setAuthenticatedActions,
    clearAuthenticatedActions,
  ] = usePersistentState({
    storageKey: 'authenticatedActions',
    initialValue: [],
  });

  useEffect(() => {
    setUser(session?.user);

    if (
      status === 'authenticated' &&
      session?.provider !== 'credentials' &&
      // @ts-ignore
      session?.user?.missingFields?.length === 0 &&
      IS_DEFAULT_CHANNEL
    ) {
      onSuccessfulAuth();
    }
    // @ts-ignore
  }, [status, session?.expires, session?.user?.missingFields?.length]);

  const actions = {
    saveQuiz: async () => {
      const data = consumeData('quiz');
      await setUserMetaData(data);
      return router.push('/profile/geschmack');
    },
    saveDegusetRating: async () => {
      const data = consumeData('degusetRating');
      const newUser = await setUserMetaData(data);
      return router.push(
        userHasQuiz(newUser) ? '/profile/geschmack' : '/bewerten/quiz',
      );
    },
    continueProductReview: async () => {
      const data = getData('ongoingProductReview');
      return router.push(data.pathname);
    },
  };

  const setUserMetaData = async (data) => {
    if (!data) {
      return;
    }
    const { result } = await executeGQL('updateUserMetadata', {
      json: JSON.stringify(data),
    });
    if (result?.id) {
      setUser(result);
      return result;
    }
  };

  const onAuthenticatedAction = (name) => {
    if (authenticatedActions.indexOf(name) < 0) {
      authenticatedActions.push(name);
      setAuthenticatedActions(authenticatedActions);
    }
  };

  const onSuccessfulAuth = async () => {
    for (let i = 0; i < authenticatedActions.length; i++) {
      try {
        await actions[authenticatedActions[i]]();
      } catch (e) {
        console.error(e);
      }
    }
    if (!checkoutToken && user?.checkout?.token) {
      setCheckoutToken(user.checkout.token);
    }
    clearAuthenticatedActions();
  };

  const credentialsSignIn = async (values) => {
    const { ok, error } = await signIn('credentials', {
      redirect: false,
      email: values.email,
      password: values.password,
    });
    if (ok && !error) {
      if (IS_DEFAULT_CHANNEL) {
        await onSuccessfulAuth();
      } else {
        await router.push('/');
      }
      return true;
    }
    return false;
  };

  const credentialsSignUp = async (values) => {
    if (values.subscribeNewsletter) {
      await executeGQL('newsLetterSubscription', {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      });
    }
    const { hasErrors, errors } = await executeGQL('accountRegister', {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      company: values.company,
      address: values.address,
      birthday: values.birthday,
      redirectUrl: `${BASE_URL}/profile/verify`,
    });
    if (!hasErrors) {
      if (IS_DEFAULT_CHANNEL) {
        const { ok } = await signIn('credentials', {
          redirect: false,
          email: values.email,
          password: values.password,
        });
        if (ok) {
          const user = await executeGQL('currentUser');
          setUser(user);
          await onSuccessfulAuth();
          return;
        }
      } else {
        router.push('/waitlist');
        return;
      }
    }
    return hasErrors ? errors : true;
  };

  const completeSignUp = async (values) => {
    const { hasErrors, errors, result } = await executeGQL(
      'completeUserSignUp',
      {
        newsletter: values.subscribeNewsletter,
        email: values.email || user?.email || session?.user?.email,
      },
    );
    if (!hasErrors) {
      setUser(result);
      await onSuccessfulAuth();
    }

    return {
      hasErrors,
      errors,
    };
  };

  const setRatingStatistics = (ratingsStatistics) => {
    setUser({ ...user, ratingsStatistics });
  };

  const isLoggedIn =
    status === 'authenticated' && user?.missingFields?.length === 0;

  // checkout stuff
  const { data: checkout, error } = useSWR(
    getCheckoutSwrKey(checkoutToken),
    () => fetchCheckoutData({ checkoutToken, clearCheckoutToken }),
  );
  if (error) {
    console.error('can not fetch checkout data', error);
  }

  const getLines = () => checkout?.lines ?? [];

  const totalItemsCount = checkout?.totalItemsCount ?? 0;

  const getLine = (id) => getLines().find((line) => id == line.variant.id);

  const getQuantityInCart = (variantId) => getLine(variantId)?.quantity ?? 0;

  const addToCart = (product, variantId, quantity = 1) => {
    setQuantityInCart(
      product,
      variantId,
      getQuantityInCart(variantId) + quantity,
    );
    overlayContext.showAddToCart(true);
  };

  const setQuantityInCart = async (product, variantId, quantity) => {
    const checkout = await ensureCheckoutLoaded();
    if (checkout) {
      const line = checkout.lines.find((l) => l.variant.id === variantId);
      if (line) {
        if (line && quantity < line.quantity) {
          trackRemoveFromCart(product, line.quantity - quantity);
        } else if (quantity > line.quantity) {
          trackAddToCart(product, quantity - line.quantity);
        }
      }

      updateLines({
        checkout,
        lines: [{ variantId, quantity }],
      });
    }
  };

  const ensureCheckoutLoaded = async () => {
    if (checkoutToken) {
      return checkout;
    }
    const {
      success,
      token,
      checkout: newCheckout,
    } = await createCheckout(user);
    if (!success) {
      logError('UserContextProvider.ensureCheckoutLoaded failed');
    }
    setCheckoutToken(token);
    return newCheckout;
  };

  const getAddresses = async () => {
    const user = await executeGQL('getAddresses');
    const defaultAddress = () => ({
      id: '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      companyName: '',
      streetAddress1: '',
      streetAddress2: '',
      postalCode: '',
      city: '',
    });

    const shippingAddress =
      user.addresses?.find((a) => {
        return a.isDefaultShippingAddress;
      }) || defaultAddress();
    const billingAddress =
      user.addresses?.find((a) => {
        return a.isDefaultBillingAddress;
      }) || defaultAddress();

    const useSameAddress = shippingAddress.id === billingAddress.id;

    return { shippingAddress, billingAddress, useSameAddress };
  };

  return (
    <UserContext.Provider
      value={{
        actions,
        user: user,
        mutateUser: setUser,
        provider: session?.provider,
        credentialsSignIn,
        getAddresses,
        credentialsSignUp,
        completeSignUp,
        onAuthenticatedAction,
        setRatingStatistics,
        setUserMetaData,
        isLoggedIn,
        userInitialized:
          status !== 'loading' &&
          (!isLoggedIn ||
            (isLoggedIn &&
              (authenticatedActions.length === 0 || !IS_DEFAULT_CHANNEL))),
        checkout,
        addToCart,
        totalItemsCount,
        setQuantityInCart,
        getQuantityInCart,
        checkoutInitialized: (checkoutToken && checkout) || !checkoutToken,
        clearCheckoutToken,
        showBanner,
        setShowBanner,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContextProvider;

export const useUser = () => useContext(UserContext);

export const userHasQuiz = (user) =>
  user && user?.metadata?.whiteStructure && user?.metadata?.whiteStructure;

export const withUser = (fn) => {
  return (props) => {
    const { user, status } = useUser();
    return fn({
      ...props,
      ...{ user, userInitialized: status !== 'loading' },
    });
  };
};

// export const hasRating = (user, baseSku) => {
//     return (
//         user?.ratingsStatistics?.ratings.massRater ||
//         user?.ratingsStatistics?.ratings.indexOf(baseSku) > -1
//     );
// };

import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import ToastProvider, { closeToast } from '../components/toast/toast-context';
import UserContextProvider from '../components/user-context';
import WishlistContextProvider from '../components/wishlist/wishlist-context';
import useRouterScroll from '../lib/router';

import '../app.scss';
import CartOverlayProvider from '../components/cart/cart-overlay';
import { setData } from '../lib/storage';

const EdvinApp = ({ Component, pageProps }) => {
  useRouterScroll();
  const router = useRouter();

  useEffect(() => {
    if (router.query.error) {
      setData('queryError', router.query.error, true);
    }
    if (router.query?.feature) {
      setData(`feature-${router.query.feature}`, true, true);
    }
    if (router.query?.noExternalScripts) {
      setData(`noExternalScripts`, true, true);
    }
  }, [
    router.query?.error,
    router.query?.feature,
    router.query?.noExternalScripts,
  ]);

  useEffect(() => {
    const handleRouteChange = () => {
      closeToast();
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <SessionProvider session={pageProps.session}>
      <ToastProvider>
        <CartOverlayProvider>
          <UserContextProvider>
            <WishlistContextProvider>
              <Component {...pageProps} />
            </WishlistContextProvider>
          </UserContextProvider>
        </CartOverlayProvider>
      </ToastProvider>
    </SessionProvider>
  );
};

export default EdvinApp;

export const New = ({ className = '' }) => {
  return (
    <svg
      width='83'
      height='84'
      viewBox='0 0 83 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M41.4933 7.62079L47.6993 0.5L51.5297 9.13328L59.5507 4.16747L60.6852 13.5503L69.8006 11.1812L68.119 20.4837L77.5414 20.8986L73.1905 29.291L82.0658 32.4766L75.4461 39.2226L83 44.8845L74.6987 49.3684L80.2373 57.0112L70.9884 58.8449L74.0314 67.7861L64.6623 66.7956L64.9426 76.2588L56.2676 72.5377L53.7585 81.6529L46.5782 75.536L41.4933 83.5L36.4218 75.536L29.2282 81.6529L26.7191 72.5377L18.0574 76.2588L18.3377 66.7956L8.96864 67.7861L12.0116 58.8449L2.76266 57.0112L8.30133 49.3684L0 44.8845L7.5406 39.2226L0.920888 32.4766L9.80945 29.291L5.45859 20.8986L14.881 20.4837L13.1994 11.1812L22.3148 13.5503L23.4359 4.16747L31.457 9.13328L35.3007 0.5L41.4933 7.62079Z'
        fill='#688F68'
      />
      <path
        d='M30.906 32.0176H33.831V49.1176H32.681L23.506 39.0926C23.081 38.6426 22.556 38.0926 22.081 37.5426C22.131 38.1676 22.181 38.7926 22.181 39.3926V49.0176H19.256V31.9176H20.381L29.281 41.6926C29.831 42.2676 30.406 42.8676 30.981 43.5176C30.931 42.6926 30.906 41.9426 30.906 41.2426V32.0176ZM40.9316 46.4176H48.9066V49.0176H37.9816V32.0176H48.6816V34.6176H40.9316V39.1176H47.7066V41.6426H40.9316V46.4176ZM58.5703 49.3426C54.6203 49.3426 51.7453 47.0676 51.7453 42.9926V32.0176H54.6953V42.7926C54.6953 45.3926 56.2453 46.6676 58.5703 46.6676C60.8703 46.6676 62.4203 45.3926 62.4203 42.7926V32.0176H65.3703V42.9926C65.3703 47.0676 62.4953 49.3426 58.5703 49.3426Z'
        fill='white'
      />
    </svg>
  );
};

/* eslint-disable no-undef */

import { fetchJson } from './api';

export const createDeployMessage = async ({
  text,
  data = {},
  system,
  type,
}) => {
  const msg = `${system}: ${text} ${JSON.stringify(data)}`;
  if (type == 'Info') {
    console.info(msg);
  } else {
    console.error(msg);
  }

  if (process.env.DEPLOY_MESSAGES_TOKEN) {
    const res = await fetchJson(
      `${process.env.API_URI}/deploy-create-message`,
      {
        method: 'POST',
        body: JSON.stringify({
          text,
          system,
          type,
          data,
        }),
        headers: {
          Authentication: process.env.DEPLOY_MESSAGES_TOKEN,
        },
      },
    );
    if (!res.ok || res.hasErrors) {
      console.error(res);
    }
  }
};

export const convertStrToDate = (dateStr) => {
  if (!dateStr) {
    return new Date().toLocaleDateString('de-CH');
  }

  return new Date(Date.parse(dateStr)).toLocaleDateString('de-CH');
};

export const getNextDayOfWeek = (date, dayOfWeek) => {
  const resultDate = new Date(date.getTime());
  resultDate.setDate(
    date.getDate() + (((7 + dayOfWeek - date.getDay() - 1) % 7) + 1),
  );
  return resultDate;
};

const germanOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export const germanDateFormat = (date) =>
  date.toLocaleDateString('de-DE', germanOptions);

export const sysDateFormat = (date) => {
  const year = date.getFullYear().toString().slice(-2).padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `20${year}-${month}-${day}`;
};

import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useRouterScroll() {
  const router = useRouter();
  useEffect(() => {
    const handler = () => {
      const hash = location.hash;
      if (hash) {
        scrollToElement(hash.substr(2));
      } else {
        window.scrollTo(0, 0);
      }
    };
    router.events.on('routeChangeComplete', handler);
    return () => {
      router.events.off('routerChangeComplete', handler);
    };
  });
}

export const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    const offset = 165;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;

    window.scrollTo({
      top: elementPosition - offset,
      behavior: 'smooth',
    });
  }
};

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, createContext, useContext, useState } from 'react';
import { t } from '../../generated/i18n';
import { Cart } from './cart';

export const CartOverlayContext = createContext({
  // eslint-disable-next-line no-unused-vars
  showAddToCart(visible) {},
  showCart(visible) {},
  close() {},
  config: {
    visible: false,
    showAddToCart: true,
  },
});

export const CartOverlayProvider = ({ children }) => {
  const [config, setConfig] = useState({
    visible: false,
    showAddToCart: true,
  });

  const showAddToCart = () => {
    setConfig({
      visible: true,
      showAddToCart: true,
    });
  };

  const showCart = () => {
    setConfig({
      visible: true,
      showAddToCart: false,
    });
  };

  const close = () => {
    setConfig({
      ...config,
      visible: false,
    });
  };

  return (
    <CartOverlayContext.Provider
      value={{ config, showAddToCart, showCart, close }}
    >
      {children}
    </CartOverlayContext.Provider>
  );
};

export default CartOverlayProvider;

export const BaseCartOverlay = () => {
  const overlayContext = useContext(CartOverlayContext);

  return (
    <>
      <Transition.Root show={overlayContext.config.visible} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          onClose={() => overlayContext.close()}
        >
          <div className='fixed inset-0' />

          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-500'
                  enterFrom='translate-y-full sm:translate-y-0 sm:translate-x-full'
                  enterTo='translate-y-0 sm:translate-y-0 sm:translate-x-0'
                  leave='transform transition ease-in-out duration-500'
                  leaveFrom='translate-y-0 sm:translate-y-0 sm:translate-x-0'
                  leaveTo='translate-y-full sm:translate-y-0 sm:translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto w-screen sm:max-w-md'>
                    <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                      <div className='flex flex-col px-6 py-2'>
                        <div className='mb-10 flex justify-between lg:mb-12'>
                          <div className='font-bold uppercase text-green lg:max-w-1/2'>
                            {/* TODO translate */}
                            {overlayContext.config.showAddToCart
                              ? t('Erfolgreich in den Warenkorb gelegt')
                              : t('Dein Warenkorb')}
                          </div>

                          <button
                            type='button'
                            className='mb-2 ml-4 w-[18px]'
                            onClick={() => overlayContext.close()}
                          >
                            <span className='sr-only'>{t('close')}</span>
                            <svg
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1 19.8687L19.8687 1'
                                stroke='#231F20'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                              />
                              <path
                                d='M19.8687 19.8687L1 1'
                                stroke='#231F20'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                              />
                            </svg>
                          </button>
                        </div>

                        <Cart overlayContext={overlayContext} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const CartOverlay = React.memo(BaseCartOverlay);

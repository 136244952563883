import { appellationSVG } from '../generated/appellations';
import { t } from '../generated/i18n';
import { executeGQL, search } from './api';
import { createDeployMessage } from './deploy-messages';
import {
  PRODUCT_DETAIL_LARGE_IMAGE_SIZE,
  PRODUCT_DETAIL_SMALL_IMAGE_SIZE,
  calcDiscountStickerOffset,
  calcImageProps,
  calcProductImages,
} from './images';
import { firstValue, removeHtmlTags } from './utils';

export const getFilteredUrl = (filter) => {
  return `/wein?filter=${filter}&sort=selling--desc`;
};

export const ESSENTIALS_URL = `essential--true&sort=selling--desc`;

export const multiFilter = (filters) => {
  return filters.reduce((a, v, i) => {
    if (v) {
      return `${a.length > 0 ? a + '&' : ''}filter${i > 0 ? i : ''}=${v}`;
    }
    return a;
  }, '');
};

function normalizeChars(str) {
  return str
      .normalize('NFD') // Decompose combined characters
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .toLowerCase(); // Convert to lowercase
}

export const getProductMap = (product) => {
  const land = normalizeChars(product.attributes.land?.value);
  const regions = [
  `${land}_${normalizeChars(product.attributes['region-manual']?.value)}`,
  `${land}_${normalizeChars(product.attributes.appellation?.value)}`,
  `${land}_${normalizeChars(product.attributes.region?.value)}`,
  land,
  ];

  let map = null;
  regions.forEach((v) => {
    if (map) {
      return
    }
    const maybe = appellationSVG[v];
      if (maybe) {
          map = maybe;
      }
    }
  );
  return map
}

export const getUserSuggestions = (data) => {
  if (data) {
    if (data.dreamWineFilterRed || data.dreamWineFilterWhite) {
      return {
        query: multiFilter([
          data.dreamWineFilterRed,
          data.dreamWineFilterWhite,
        ]),
        title: t('yourTasteHeading'),
        description: t('yourdreamwinedescription'),
      };
    } else if (data.redStructure && data.whiteStructure) {
      return {
        query: `filter=struktur--${data.redStructure}||struktur--${data.whiteStructure}&sort=selling--desc`,
        title: t('yourTasteHeading'),
        description: t('yourTasteDescription'),
      };
    }
  }

  return {
    query: `filter=${ESSENTIALS_URL}`,
    title: t('homeEssentials'),
    description: t('homeEssentialsText'),
  };
};

export const getProductDetail = async (slug) => {
  const params = `filter=slug--${encodeURIComponent(
    slug,
  )}&size=1&dt=data_detail`;
  const result = (await search(params)).results;
  const product = result.length > 0 ? result[0] : null;

  let related = null;
  if (product) {
    product.descriptionText = removeHtmlTags(product.description);
    product.seoTitle = firstValue([product.seoTitle, product.name], null);
    product.seoDescription = firstValue(
      [product.seoDescription, product.descriptionText],
      null,
    );

    if (product.type == 'wein') {
      product.regionMap = getProductMap(product)
    }

    if (product.images.length) {
      product.images.forEach((i) => {
        calcImageProps(i, PRODUCT_DETAIL_SMALL_IMAGE_SIZE);
        calcImageProps(i, PRODUCT_DETAIL_LARGE_IMAGE_SIZE);
      });

      calcDiscountStickerOffset(
        product,
        product.images[0],
        PRODUCT_DETAIL_SMALL_IMAGE_SIZE.height,
      );
      calcDiscountStickerOffset(
        product,
        product.images[0],
        PRODUCT_DETAIL_LARGE_IMAGE_SIZE.height,
      );
    }

    // related products from same category or structure
    const relatedFilter =
      product.type == 'wein'
        ? `struktur--${product.attributes.struktur.value}`
        : `categories--${product.category?.slug}`;

    related = (
      await search(`filter=${relatedFilter}&sort=selling--desc&size=${4}`)
    ).results;

    if (related) {
      related = related.filter((r) => r.slug !== product.slug).splice(0, 3);
      related.forEach((product) => calcProductImages(product));
    } else {
      related = [];
    }

    // included
    let field;
    for (field of ['weine', 'weissweine', 'rotweine', '3er', '6er']) {
      if (product.metadata[field]) {
        product[field] = await getProductsBySku(
          product.metadata[field].split(','),
          slug,
        );
        product[field].forEach((product) => calcProductImages(product));
      }
    }
  }

  return {
    product,
    related,
  };
};

export const getProductsByIds = async (pids) => {
  const url = `filter=pids--${encodeURIComponent(
    pids.join('||pids--'),
  )}&sort=selling--desc&size=${pids.length}`;
  return await search(url);
};

export const getProductBySlug = async (slug, size = 1) => {
  return (
    await search(
      `filter=slug--${encodeURIComponent(
        slug,
      )}&sort=selling--desc&size=${size}`,
    )
  ).results;
};

export const getProductsBySku = async (skuList, identifier = null) => {
  // search for products, keep order and variant sku has a higher precedence thant base sku
  const get = async (_skuList) =>
    (
      await search(
        `filter=${_skuList.map((sku) => 'sku--' + sku.trim()).join('||')}`,
      )
    ).results;
  const baseSkuList = skuList.map((s) => s.trim().slice(0, 7));
  const results = [];

  let candidates = [...(await get(skuList)), ...(await get(baseSkuList))];

  const notFound = [];
  for (let i = 0; i < skuList.length; i++) {
    const sku = skuList[i];
    const baseSku = baseSkuList[i];

    let needle =
      candidates.find((p) => p.defaultVariant?.sku === sku) ||
      candidates.find((p) => p.metadata['prod-nr-kurz'] === baseSku);

    if (needle) {
      results.push(needle);
    } else if (identifier) {
      notFound.push({ sku: `${sku} or ${baseSku}` });
    }
  }
  if (notFound.length > 0) {
    createDeployMessage({
      text: `'${identifier}': Linked product(s) not found`,
      data: notFound.length === 1 ? notFound[0] : notFound,
      system: 'Shop',
      type: 'Error',
    });
  }
  return results;
};

export const fetchProductRatingSummary = async (baseSku) => {
  const result = await executeGQL('getProductRatingSummary', {
    baseSku: baseSku,
  });
  return result;
};

export const isWineOnly = (products, guessLength = 20) =>
  products.length > 0 &&
  products
    .slice(0, guessLength - 1)
    .reduce(
      (acc, product) => acc && product.productType?.slug === 'wein',
      true,
    );

export const guessProductRedirect = async (parentSlug, productSlug) => {
  const guess = (
    await search(`q=${productSlug}&dt=data_teaser_mini&size=20`)
  ).results
    .filter((r) => r.parent_slug === parentSlug)
    .at(0);

  if (guess) {
    return `/${guess.parent_slug}/${encodeURIComponent(guess.slug)}`;
  }
};
import Image from 'next/image';
import { imgixLoader, PRODUCT_PREVIEW_IMAGE_SIZE } from '../../lib/images';
import { Discount } from './discount';
import { New } from './new';

const ProductImage = ({
  product,
  size = PRODUCT_PREVIEW_IMAGE_SIZE,
  className = '',
  onClick = null,
  preload = false,
  showDiscount = true,
  align = 'text-center',
  heroImageClassName = undefined,
}) => {
  let image;

  if (product.images.length > 0) {
    image = product.images[0];
  } else {
    image = {
      height: 1,
      width: 1,
    };
  }

  // {height: size.height+"px", width:  size.width+"200px"}
  return (
    <div
      className={`${heroImageClassName || 'relative'} ${className} ${align}`}
      style={
        !heroImageClassName
          ? { height: size.height + 'px', width: size.width + 'px' }
          : {}
      }
    >
      {image.url && (
        <Image
          className={`${onClick ? 'cursor-pointer' : ''}`}
          onClick={onClick}
          loader={imgixLoader}
          priority={preload}
          src={image.url}
          alt={image.alt ? image.alt : product.name}
          // {...calcImageProps(image, size)}
          // width={size.width}
          // height={size.height}
          fill={true}
          style={{ objectFit: 'contain' }}
        />
      )}
      {showDiscount && product.pricing?.onSale ? (
        <Discount
          pricing={product.pricing}
          className='absolute right-0 top-0'
        />
      ) : showDiscount && product.attributes?.neuheit?.value ? (
        <New className='absolute right-0 top-0' />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductImage;

export const ON_CLIENT = globalThis === globalThis.window;
export const ON_SERVER = !ON_CLIENT;

export function range(from, to) {
  const list = [];
  for (let i = from; i < to; i++) {
    list.push(i);
  }
  return list;
}

export function inclusiveRange(from, toInclusive) {
  return range(from, toInclusive + 1);
}

export function extract(fieldName) {
  return (obj) => obj[fieldName];
}

export function selectFields(obj, fieldNames) {
  return Object.fromEntries(fieldNames.map((k) => [k, obj[k]]));
}

export function only(handler) {
  return (e, ...args) => {
    e.stopPropagation();
    e.preventDefault();

    if (handler) {
      handler(e, ...args);
    }
  };
}

export function scrollIntoViewIfNeeded(el, config = { behavior: 'smooth' }) {
  if (!el) return;
  const bounds = el.getBoundingClientRect();
  if (bounds.top < 0 || bounds.left < 0) {
    el.scrollIntoView(config);
  }
}

export const withRetry = (fn, options = {}) => {
  return (...args) => retry(() => fn(...args), options);
};

export async function retry(
  fn,
  {
    attempt = 0,
    maxAttempts = 3,
    shouldRetry = (returnValue) => returnValue == undefined,
    baseTimeout = 250,
  } = {},
) {
  attempt = Math.max(attempt, 0);
  maxAttempts = Math.max(maxAttempts, 1);
  const returnValue = await fn(attempt);
  if (!shouldRetry(returnValue) || attempt >= maxAttempts - 1) {
    return returnValue;
  }
  const timeout = (baseTimeout << attempt) + baseTimeout * Math.random();
  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve(
          retry(fn, {
            attempt: attempt + 1,
            maxAttempts,
            shouldRetry,
            baseTimeout,
          }),
        ),
      timeout,
    ),
  );
}

export const unique = (list) => [...new Set(list)];

export const mapObjectEntries = (obj, fn) =>
  // @ts-ignore
  Object.fromEntries(Object.entries(obj).map(fn));

export const mapObjectValues = (obj, fn) =>
  mapObjectEntries(obj, ([k, v]) => [k, fn(v)]);

export const deepClone = (thing) =>
  Array.isArray(thing)
    ? thing.map(deepClone)
    : thing && typeof thing === 'object'
      ? mapObjectValues(thing, deepClone)
      : thing;

const removeHtmlRegex = /(<([^>]+)>)/gi;
export const removeHtmlTags = (html) =>
  html ? html.replace(removeHtmlRegex, '') : '';

export const firstValue = (arr, defaultValue) => {
  return arr.find((el) => el) || defaultValue;
};

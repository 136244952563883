import { trackEvent } from './tracking';
import { ON_CLIENT } from './utils';

export const logError = (error) => {
  const safeStr = `${error}`;

  trackEvent({
    category: 'error',
    action: safeStr,
  });

  if (typeof error === 'string' || error instanceof String) {
    error = new Error(safeStr);
  }

  // @ts-ignore
  if (ON_CLIENT && window?.Sentry) {
    // @ts-ignore
    window.Sentry.captureException(error);
  } else {
    console.error(error);
  }
};

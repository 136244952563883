import { Transition } from '@headlessui/react';

export const FadeIn = ({ children, duration = 'duration-300', ...props }) => (
  <Transition
    enter={'transition-opacity ' + duration}
    enterFrom='opacity-0'
    enterTo='opacity-100'
    leave={'transition-opacity ' + duration}
    leaveFrom='opacity-100'
    leaveTo='opacity-0'
    {...props}
  >
    {children}
  </Transition>
);
